import type { UseFetchOptions } from 'nuxt/app'

export const useServerRequest = <T = unknown>(url: string, opts?: UseFetchOptions<T>, meta?: any) => {
  const { locale } = useI18n()
  // const runtimeConfig = useRuntimeConfig()

  // Fetch 请求 options
  const defaultOptions: UseFetchOptions<T> = {
    ...opts,
    // watch: [locale],
    onResponse({ response }) {
      response._data = {
        ...response._data.data
      }
    },
    onRequest({ options }) {
      options.headers = {
        ...options.headers,
        'Accept-Language': { en: 'en-us', ph: 'en-ph', zh: 'zh-cn' }[locale.value] || 'zh-cn'
      }
    },
    onResponseError({ request, response, options }) {
      handleError(response)
    }
  }

  // 实际请求
  return useFetch<T>(url, { ...defaultOptions } as any)
}
